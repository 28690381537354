<template>
    
    <div id="footer">
        <div class="icons">
            <a href="https://google.com"><font-awesome-icon icon="fa-brands fa-facebook fa-lg" /></a>
            <a href="https://instagram.com/talijaitalija?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr"><font-awesome-icon icon="fa-brands fa-instagram fa-lg" /></a>
        </div>
        <hr>
        <div class="footer-text">
            <!--<div class="policies">
                <a>Privatumo politika</a>
                <a>Prekių gražinimas</a>
            </div>-->
            <p>© 2023 TalijaItalija.lt. Visos teisės saugomos.</p>
        </div>

    </div>  
</template>


<script>
 
export default {
  name: 'footerComponent',
}
</script>






<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .policies{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .policies a {
        padding-left: 20px;
        cursor: pointer;
    }

    a {
        pointer-events: visible;
        color: black;
        transition: 0.3s;
    }

    a:hover{
        color: #B11E31;
    }
    svg{
        width: 30px;
        height: auto;
        padding: 1rem;
    }
    .icons{
        display: flex;
        justify-content: right;
        padding: 1rem;
        color: black;
    }
    .footer-text{
        display: flex;
        flex-direction: row;
        justify-content: space-around;

    }
    #footer{
        padding: 3rem 1.5rem 0 1.5rem;
    }
    hr{
        margin: 0 5rem 0 5rem 0;
    }

    @media (max-width: 768px) {
    .icons{
     
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .footer-text{
      flex-direction: column;
    }

    p{
        font-size: 13px;
    }
    .vb{
        font-size: 10px;
    }
  }

</style>