<template>
  <section id="pagrindinis">
  <div class="container">
    <div class="image-container">
        <img src="../assets/mainpage.png" alt="Image 1" v-motion-fade>
    </div>
    <div class="text" v-motion-fade>
      <h1 v-motion-fade >Patogu ir madinga</h1>
      <p>Kokybiška produkcija iš Italijos.</p>
      <br><br>
      <a @click="scrollToSection('galerija')" v-motion-fade>Sužinoti daugiau</a>
    </div>
  </div>


    
  </section>
</template>


<script>
export default {
  name: 'NavbarComponent', // Component name
  methods: {
    scrollToSection(section) {
      // Use JavaScript to scroll to the specified section
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>









<style scoped>
h1{
  font-size: 20px;
  text-align: center;
}
p{
  text-align: center;
  padding-bottom: 30px;
}
.text{
  text-align: center;
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container{
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 3rem;
}

#pagrindinis h1{
  text-align: center;
  font-size: 45px;
}

#pagrindinis{
  padding: 2rem 0 10rem 0;
}


img {
  height: 700px;
}
   
#pagrindinis a {
    border: 1px solid #B11E31; /* Initially, set a black border */
    transition: all 0.3s ease; /* Add a transition effect for all properties */
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    background-color: #B11E31;
    cursor: pointer;
    color: white;
}

#pagrindinis a:hover {
    background-color: white; /* Change background color to white on hover */
    color: #B11E31; /* Change text color to black on hover */
    border-color: #B11E31; /* Change border color to black on hover */
}

.image-wrapper {
  margin: 10px; /* Adjust this margin to control the separation */
}



@media (max-width: 768px) {
    .container{
      padding: 5rem 0 0 0;
      flex-direction: column;
    }

    img{
      height: auto;
      width: 100%;
    }
  }
</style>