<template>
  <div>
    <NavbarComponent />
    <HelloWorld />
    <GalerijaComponent />
    <KontaktaiComponent />
    <FooterComponent />
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';
import NavbarComponent from './components/NavbarItem.vue';
import HelloWorld from './components/HelloWorld.vue';
import GalerijaComponent from './components/GalerijaComponent.vue';
import KontaktaiComponent from './components/KontaktaiComponent.vue';
import FooterComponent from './components/footerComponent.vue';

// Use `useHead` to set document head properties
useHead({
  title: 'TalijaItalija - Minkštas itališkas apatinis trikotažas moterims',
  meta: [
    {
      name: 'description',
      content: 'Minkštas itališkas apatinis trikotažas moterims.',
    },
    {
      name: 'keywords',
      content: 'TalijaItalija.lt, Apatinis trikotažas, drabužiai moterims, apatinis trikotažas moterims, kokybiškas apatinis trikotažas',
    },
  ],
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>
