<template>
    <div id="kontaktai">
        
        <div class="kontaktai-container">
            <h2 v-motion-fade-visible-once>Apie Produktus</h2>
            <p v-motion-fade-visible-once>Visi parduodami produktai yra tvaresni. Naudojama nauja technologija leidžia sunaudoti mažiau medžiagos.
              <br><br><br>
              Produktai yra pasiūti taip, kad maloniai priglustų prie Jūsų kūno.
              <br><br><br>
              Kelnaičių sudėtis: 93% poliamidas, 7% elastanas.
              
            </p>
         
            <h2 v-motion-fade-visible-once>Kaip prižiūrėti aprangą?</h2>
           <p><b>Skalbti 30 laipsnių temperatūroje; </b> <br><br> Nebalinti; <br><br> Nelyginti; <br><br> Nevalyti sausai; <br><br> Nedžiovinti džiovyklėje ir laikyti atokiai nuo šildymo prietaisų. <br><br> Pageidautina naudoti spalvotų drabužių ploviklį. <br><br> Skalbimui mašina rekomenduojame gaminį dėti į tinklinį skalbinių maišelį, kad jis nepritrauktų pūkų.</p>

        </div>
        <div class="container" contenteditable="false">
          <img  src="../assets/PowerRibMidRiseBikeShorts.jpg" alt="Power Rib Mid Rise Bike Shorts" v-motion-fade-visible-once >           
        </div>

        
    </div>
    <br><br>
    <a href="">Grįžti į viršų</a>
</template>


<script>
export default {
  name: 'KontaktaiComponent',
}
</script>






<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container {
    display: flex;
    flex-direction: row;
  }

  .container img{
    height: 800px;
    padding: 1rem;
  }


/* Centered text */

  .centered {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
    a {
      text-decoration: none;
      color: black;      
    }

    #kontaktai{
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .kontaktai-container{
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 3rem;
    }



   

    @media (max-width: 768px) {
    .kontaktai-container{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    #kontaktai{
     
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    #kontaktai img{
      height: auto;
      width: 90%;
    
    }

    .kontaktai-container {
      padding: 1rem;
    }
  }
</style>

  