<template>
    <h1>TalijaItalija.lt</h1>
    <nav>
      <div class="desktop-menu" v-motion-fade>
       
        <a @click="scrollToSection('galerija')" class="normal-nav" v-motion-fade>Parduotuvė</a>
        <a @click="scrollToSection('kontaktai')" class="normal-nav" v-motion-fade>Apie produktus</a>
      <!--  <a id="actionbtn" @click="scrollToSection('kontaktai')" class="right" v-motion-fade>Daugiau informacijos</a> -->

        
      
      </div>
  
      <!--<div class="menu-icon" :class="{ active: isMobileMenuActive }" @click="toggleMobileMenu" v-motion-fade>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
  
      <div class="mobile-menu" :class="{ active: isMobileMenuActive }" v-motion-fade>
        <a @click="scrollToSection('kontaktai')" v-motion-fade><b>Daugiau informacijos</b></a>
        <a @click="scrollToSection('galerija')" style="z-index: 2;" v-motion-fade><b>Įsigyti</b></a>
      </div>-->
    </nav>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isMobileMenuActive: false,
      };
    },
    methods: {
      scrollToSection(section) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
          this.toggleMobileMenu(); // Close the mobile menu after clicking a link
        }
      },
      toggleMobileMenu() {
        this.isMobileMenuActive = !this.isMobileMenuActive;
      },
    },
  };
  </script>
  
  <style scoped>
  a {
    cursor: pointer;
  }

  #actionbtn{
    border: 1px solid #B11E31; /* Initially, set a black border */
    transition: all 0.3s ease; /* Add a transition effect for all properties */
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    background-color: #B11E31;
    cursor: pointer;
    color: white;
  }

  #actionbtn:hover{
    background-color: white; /* Change background color to white on hover */
    color: #B11E31; /* Change text color to black on hover */
    border-color: #B11E31; /* Change border color to black on hover */
  }

  .desktop-menu a {
    border: 1px solid transparent; /* Initially, set a transparent border */
    transition: border 0.3s ease; /* Add a transition effect */
}

.desktop-menu a:hover {
    border: 1px solid; /* Set the border to solid on hover */
}
  nav {
    margin: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .desktop-menu {
    display: flex;
    align-items: center;
  }
  
  .desktop-menu .normal-nav {
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
  }
  
  .desktop-menu .right {
    text-decoration: none;
    margin-right: auto;
    padding: 0.8rem 1.7rem 0.8rem 1.7rem;
    background-color: #B11E31;
    cursor: pointer;
    color: white;
  }
  
  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    right: 20px;
    padding: 3rem 2px 1rem 2px;
  }
  
  .menu-icon.active .bar:nth-child(1) {
    transform: translateY(11px) rotate(45deg);
  }
  
  .menu-icon.active .bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.active .bar:nth-child(3) {
    transform: translateY(-11px) rotate(-45deg);
  }
  
  .bar {
    width: 35px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: none;
    font-size: 27px;
    z-index: 1;
    
}
/*  
  
  @media (max-width: 768px) {
    .desktop-menu {
      display: none;
    }
  
    .menu-icon {
      display: flex;
    }
  
    .mobile-menu {
      display: none;
      position: absolute;
      top: 132px;
      right: 10px;
      background-color: white;
      border: dashed 1px;
      padding: 12% 5% 12% 15%;
    }
  
    .mobile-menu.active {
      display: flex;
      text-align: right;
    }

    .mobile-menu.active a{
        padding: 10px 0 0 0;
    }
  }
  */
  @media (min-width: 769px) {
    .menu-icon {
      display: none; /* Hide the menu icon on larger screens */
    }
    .mobile-menu {
      display: none; /* Hide the mobile menu on larger screens */
    }
  } 


  </style>
  