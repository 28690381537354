// Import the necessary modules from Vue and other libraries
import { createApp } from 'vue';
import App from './App.vue';
import { MotionPlugin } from '@vueuse/motion';
import { createHead } from '@vueuse/head';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './styles.css';

// Add icons to the library
library.add(faFacebook, faInstagram);

// Create the Vue app
const app = createApp(App);

// Use the VueUse Motion plugin
app.use(MotionPlugin);

// Setup head management with @vueuse/head
const head = createHead();
app.use(head);

// Register the FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Mount the app to the #app element
app.mount('#app');
