<template>
    <div id="galerija">
      <h1 v-motion-fade-visible-once>Asortimentas</h1>
      <p>Išsirinkite sau labiausiai patinkantį gaminį.</p>
      <p><b>Modelis nuotraukose: <br>170 cm (5’6”), <br> krūtinė – 82cm (32”), <br>juosmuo – 58cm (23”), <br>klubai – 89cm (35”), <br>dėvi XS-S dydį</b></p>
      <br><br>
      <div class="img-container">
            <div class="package-holder">
                <img src="../assets/brazilian.jpg" alt="Power Rib V-Shaped Brazilian Panties BLUE GRAPHITE" v-motion-fade-visible-once>
                <h2>13.90 € (1 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>BLUE GRAPHITE</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Mėlyno grafito spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/aEU02D5w9aOSeeA00j">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/fZecPp8Il6yC3zW28q">M-L</a>
                </div>

            </div>
            <div class="package-holder">
                <img src="../assets/brazilianb.jpg" alt="Power Rib V-Shaped Brazilian Panties BLACK" v-motion-fade-visible-once>
                <h2>13.90 € (1 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>BLACK</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Juodos spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/6oE9Dd1fTg9cc6s7sI">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/eVaaHh3o15uy1rO00f">M-L</a>
                </div>
            </div>
            <div class="package-holder">
                <img src="../assets/brazilianc.jpg" alt="Power Rib V-Shaped Brazilian Panties COFFEE" v-motion-fade-visible-once>
                <h2>13.90 € (1 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>COFFEE</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Kavos spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/00gdTt6Ad4qu9YkcN0">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/5kA6r16Ad5uy5I4dR7">M-L</a>
                </div>
            </div>
            <div class="package-holder">
                <img src="../assets/braziliang.jpg" alt="Power Rib V-Shaped Brazilian Panties IGUANA" v-motion-fade-visible-once>
                <h2>13.90 € (1 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>IGUANA</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Iguanos spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/dR6bLl1fT2imeeA4gt">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/28o8z90bP0ae8Ug3co">M-L</a>
                </div>
            </div>
            <div class="package-holder">
                <img src="../assets/brazilian.jpg" alt="Power Rib V-Shaped Brazilian Panties BLUE GRAPHITE" v-motion-fade-visible-once>
                <h2>35.90 € (3 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>BLUE GRAPHITE</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Mėlyno grafito spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/7sIbLlgaNe148Ug5kL">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/6oEeXx6Ad0ae3zW00q">M-L</a>
                </div>
            </div>
            <div class="package-holder">
                <img src="../assets/brazilianb.jpg" alt="Power Rib V-Shaped Brazilian Panties BLACK" v-motion-fade-visible-once>
                <h2>35.90 € (3 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>BLACK</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Juodos spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/8wM3ePgaN9KOc6s7sV">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/9AQeXx4s52im2vS5kM">M-L</a>
                </div>
            </div>
            <div class="package-holder">
                <img src="../assets/brazilianc.jpg" alt="Power Rib V-Shaped Brazilian Panties COFFEE" v-motion-fade-visible-once>
                <h2>35.90 € (3 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>COFFEE</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Kavos spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/aEUeXx5w93mqgmI8wU">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/dR602DgaN5uy4E028x">M-L</a>
                </div>
            </div>
            <div class="package-holder">
                <img src="../assets/braziliang.jpg" alt="" v-motion-fade-visible-once>
                <h2>35.90 € (3 vnt.)</h2>
                <h3>Power Rib V-Shaped Brazilian Panties<br>IGUANA</h3>
                <p>Patogios besiūlės braziliško stiliaus kelnaitės. <br>(Iguanos spalvos)</p>
                <p>Įsigyti galite:</p>
                <div class="buy-now-holder">
                    <a id="buynow" href="https://buy.stripe.com/8wM7v5f6J3mq4E08wT">XS-S</a>
                    <a id="buynow" href="https://buy.stripe.com/5kAbLl2jX7CGfiE4gC">M-L</a>
                </div>
            </div>

            

        </div>
        <div class="package-holder">
            <h2>Matėte daugiau produktų, <br>o gal norite komplekso? </h2>
            <h3>Susisiekti galite per mūsų <a href="https://www.facebook.com/profile.php?id=61553185151949"> FACEBOOK </a>arba <a href="https://www.instagram.com/talijaitalija/">INSTAGRAM </a> paskyras ir mes paruošime užsakymą.</h3>
        </div>
        
    </div>
</template>

<script>
export default {
  name: 'GalerijaComponent',
}
</script>

<style scoped>
    .buy-now-holder{
        display: flex;
        flex-direction: row;
    }
    #buynow{
        padding: 20px 40px 20px 40px;
        margin: 20px;
        transition: 0.3s;
        color: white;
        text-decoration: none;
        font-weight: 700;
        background-color: black;
    }
    #buynow:hover{
        background-color: white;
        color: black;
        border: solid 1px black;
    }
     #galerija{
        height: auto;
        padding: 2rem 0 10rem 0;
    }

    .package-holder{
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .img-container img{
        height: 500px;
        width: auto;
        margin: 20px;
    }

    .img-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }


    @media (max-width: 768px) {
        #galerija {
            flex-direction: column;
        }

        .img-container {
            flex-direction: column; /* Change this to row for desktop */

        }

        .img-container img{
            height: auto;
        }

        .img-container img{
            width: 100%;
        }
    }
</style>
